import { api } from "../services/api";
import router from "../router";
import { displayErrMsg } from "../utils/errors";

export default {
  namespaced: true,
  state: {
    user: null,
    users: [],
    roles: [],
    groups: [],
    group: null,
    saml: null,
    workgroups: [],
    groupWorkgroup: {},
  },

  mutations: {
    async UPDATE_USER(state, { data }) {
      let flip = data.flip ? data.flip : false;

      if (flip) {
        data.status = data.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
        delete data.flip;
      }

      await api
        .put("users", data)
        .then(() => {
          for (let user of state.users) {
            if (data.email === user.email) {
              user.status = data.status;
              break;
            }
          }

          localStorage.setItem("user", JSON.stringify(data));
          displayErrMsg("GLOBAL_SUCCESS_MODIFY", "Usuário", "UPDATE_USER");
        })
        .catch((err) => {
          // TODO: Check
          if (
            err.response.data.message.includes("exceeds-number-of-licenses")
          ) {
            window.toastr.error(
              "Número de licenças ativas para este tipo de usuário já foi atingida.",
              "Sem licenças."
            );
          } else {
            displayErrMsg(err.response.data.code, "Usuário", "UPDATE_USER");
          }
        });
    },
    async UPDATE_EXTERNAL_USER(state, { data }) {
      let flip = data.flip ? data.flip : false;

      if (flip) {
        data.status = data.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
        delete data.flip;
      }

      await api
        .put("users/external", data)
        .then(() => {
          for (let user of state.users) {
            if (data.email === user.email) {
              user.status = data.status;
              break;
            }
          }

          localStorage.setItem("user", JSON.stringify(data));
          displayErrMsg(
            "GLOBAL_SUCCESS_MODIFY",
            "Usuário",
            "UPDATE_EXTERNAL_USER"
          );
        })
        .catch((err) => {
          if (
            err.response.data.message.includes("exceeds-number-of-licenses")
          ) {
            window.toastr.error(
              "Número de licenças ativas para este tipo de usuário já foi atingida.",
              "Sem licenças."
            );
          } else {
            displayErrMsg(
              err.response.data.code,
              "Usuário",
              "UPDATE_EXTERNAL_USER"
            );
          }
        });
    },
    async GET_USERS(state) {
      await api
        .get("users")
        .then((response) => {
          state.users = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Usuário", "GET_USERS");
        });
    },
    async DELETE_USER(state, { data }) {
      await api
        .delete(`users?email=${data.email}`)
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_DELETE", "Usuário", "DELETE_USER");
          state.users = state.users.filter((user) => user.email !== data.email);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Usuário", "DELETE_USER");
        });
    },
    async DELETE_EXTERNAL_USER(state, { data }) {
      await api
        .delete(`users/external?email=${data.email}`)
        .then(() => {
          displayErrMsg(
            "GLOBAL_SUCCESS_DELETE",
            "Usuário",
            "DELETE_EXTERNAL_USER"
          );
          state.users = state.users.filter((user) => user.email !== data.email);
        })
        .catch((err) => {
          displayErrMsg(
            err.response.data.code,
            "Usuário",
            "DELETE_EXTERNAL_USER"
          );
        });
    },

    async SAVE_USER(state, { data }) {
      await api
        .post(`users`, data)
        .then((res) => {
          displayErrMsg("GLOBAL_SUCCESS_ADD", "Usuário", "SAVE_USER");
          let { data } = res;
          state.users.push({ data });
          router.push({ path: "/user" });
        })
        .catch((err) => {
          // TODO: Special Cases
          // if (
          //   err.response.data.message ==
          //   "400 Bad Request: {'email': ['Not a valid email address.']}"
          // ) {
          //   window.toastr.warning("Email inválido", "Email");
          // }
          // if (
          //   err.response.data.message ===
          //   "400 Bad Request: user-weak-password - Minimum 8 length, 1x lowercase(s), 1x uppercase(s), 1x number(s), 1x special(s)"
          // ) {
          //   window.toastr.warning(
          //     "Preencha todas as requisições para a senha forte.",
          //     "Senha fraca"
          //   );
          // }
          if (
            err.response.data.message.includes("exceeds-number-of-licenses")
          ) {
            window.toastr.error(
              "Número de licenças ativas para este tipo de usuário já foi atingida.",
              "Sem licenças."
            );
          } else {
            displayErrMsg(err.response.data.code, "Usuário", "SAVE_USER");
          }
        });
    },

    async SAVE_USER_EXTERNAL(state, { data }) {
      await api
        .post(`users/external`, data)
        .then((res) => {
          displayErrMsg("GLOBAL_SUCCESS_ADD", "Usuário", "SAVE_USER_EXTERNAL");
          let { data } = res;
          state.users.push({ data });
          router.push({ path: "/user" });
        })
        .catch((err) => {
          // if (
          //   err.response.data.message ==
          //   "400 Bad Request: {'email': ['Not a valid email address.']}"
          // ) {
          //   window.toastr.warning("Email inválido", "Email");
          // }
          // if (
          //   err.response.data.message ===
          //   "400 Bad Request: user-weak-password - Minimum 8 length, 1x lowercase(s), 1x uppercase(s), 1x number(s), 1x special(s)"
          // ) {
          //   window.toastr.warning(
          //     "Preencha todas as requisições para a senha forte.",
          //     "Senha fraca"
          //   );
          // }
          // if (
          //   err.response.data.message ===
          //   "403 Forbidden: exceeds-number-of-licenses"
          // ) {
          //   window.toastr.error(
          //     "Número de licenças ativas para este tipo de usuário atingida.",
          //     "Sem licenças."
          //   );
          // }
          if (
            err.response.data.message.includes("exceeds-number-of-licenses")
          ) {
            window.toastr.error(
              "Número de licenças ativas para este tipo de usuário já foi atingida.",
              "Sem licenças."
            );
          } else {
            displayErrMsg(
              err.response.data.code,
              "Usuário",
              "SAVE_USER_EXTERNAL"
            );
          }
        });
    },

    async GET_GROUPS(state) {
      await api
        .get("users/groups")
        .then(async (response) => {
          state.groups = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Grupo", "GET_GROUPS");
        });
    },
    async GET_GROUPS_AND_WORKGROUPS(state) {
      await api
        .get("users/groups")
        .then(async (response) => {
          state.groups = response.data;
          /* for each group, call GET_WORKGROUPS with response.data[i].name */
          for (const groupObj of response.data) {
            await api
              .get(`/users/group/${groupObj.name}/workgroups`)
              .then((response) => {
                state.groupWorkgroup[groupObj.name] = response.data;
              });
          }
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Grupo", "GET_GROUPS");
        });
    },
    async GET_GROUP(state, { data }) {
      await api
        .get(`users/groups?sortBy=name&sortOrder=asc&name=${data.group_name}`)
        .then((response) => {
          state.group = response.data[0];
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Grupo", "GET_GROUP");
        });
    },
    async DELETE_GROUP(state, { data }) {
      await api
        .delete(`users/groups?name=${data.group_name}`)
        .then(() => {
          displayErrMsg("GLOBAL_SUCCESS_DELETE", "Grupo", "DELETE_GROUP");
          state.groups = state.groups.filter(
            (group) => group.name !== data.group_name
          );
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Grupo", "DELETE_GROUP");
        });
    },
    async SAVE_GROUP(state, { data }) {
      await api
        .post(`users/groups`, data)
        .then((res) => {
          displayErrMsg("GLOBAL_SUCCESS_ADD", "Grupo", "SAVE_GROUP");
          let { data } = res;
          state.groups.push({ data });
          state.group = data;
          router.push({ path: "/group" });
        })
        .catch((err) => {
          if (
            err.response.data.message.includes("exceeds-number-of-licenses")
          ) {
            window.toastr.error(
              "O número de licenças passou do limite.",
              "Não foi possível salvar o grupo"
            );
          } else if (
            err.response.data.message.includes("ms-pbi-no-access-token")
          ) {
            window.toastr.error(
              "Falha na autenticação do Power BI",
              "Tente novamente ou entre em contato com um administrador."
            );
          } else if (
            err.response.data.message.includes("ms-pbi-no-dash-reports")
          ) {
            window.toastr.error(
              "Nenhum relatório de PowerBI disponível para exibição.",
              "Entre em contato com um administrador para mais informações."
            );
          } else if (
            err.response.data.message.includes("entity-type-already-exists")
          ) {
            displayErrMsg(err.response.data.code, "Group", "SAVE_GROUP_EXISTS");
          } else {
            displayErrMsg(err.response.data.code, "Grupo", "SAVE_GROUP");
          }
        });
    },
    async UPDATE_GROUP(state, { data }) {
      await api
        .put(`users/groups`, data)
        .then((res) => {
          displayErrMsg("GLOBAL_SUCCESS_MODIFY", "Grupo", "UPDATE_GROUP");
          let { data } = res;
          state.groups.push({ data });
          state.group = data;
          router.push({ path: "/group" });
        })
        .catch((err) => {
          // TODO: Special Cases
          // if (
          //   err.response.data.message ===
          //   "403 Forbidden: exceeds-number-of-licenses"
          // ) {
          //   window.toastr.error(
          //     "Número de licenças da plataforma atingido.",
          //     "Licenças excedidas"
          //   );
          // } else {
          //   window.toastr.error("Grupo não pôde ser atualizado", "Grupo");
          // }
          if(
            err.response.data.message.includes("exceeds-number-of-licenses")
          ){
            displayErrMsg("409", 'Grupo', "UPDATE_GROUP");
          }
          else{
            displayErrMsg(err.response.data.code, 'Grupo', "UPDATE_GROUP");
          }
        });
    },
    async GET_WORKGROUPS(state, { data }) {
      await api
        .get(`users/group/${data.group_name}/workgroups`)
        .then((response) => {
          state.workgroups = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Workgroup", "GET_WORKGROUPS");
        });
    },
    async GET_USER_WORKGROUPS(state, { data }) {
      await api
        .get(`users/${data.email}/dashboards/pbi/groups`)
        .then((response) => {
          state.workgroups = response.data;
        })
        .catch((err) => {
          displayErrMsg(
            err.response.data.code,
            "Workgroup",
            "GET_USER_WORKGROUPS"
          );
        });
    },
    async ADD_WORKGROUP(state, data) {
      await api
        .post(`users/group/${data.group_name}/workgroups`, {
          name: data.workgroup_name,
        })
        .then((response) => {
          let wkp = response.data;
          displayErrMsg("GLOBAL_SUCCESS_ADD", "Workgroup", "ADD_WORKGROUP");
          state.workgroups.push(wkp);
        })
        .catch((err) => {
          // TODO: Special Cases
          // if (error.response && error.response.data.code === 401) {
          //   window.toastr.warning(
          //     "Grupo não possui acesso ao PowerBI.",
          //     "Erro"
          //   );
          // } else if (error.response && error.response.data.code === 403) {
          //   window.toastr.warning(
          //     "Usuário sem permissão para efetuar esta operação.",
          //     "Alerta"
          //   );
          // } else if (error.response && error.response.data.code === 409) {
          //   window.toastr.warning(
          //     "Workgroup especificado já existe neste grupo.",
          //     "Alerta"
          //   );
          // } else {
          //   window.toastr.error("Erro no servidor", "Workgroup");
          // }
          displayErrMsg(err.response.data.code, "Workgroup", "ADD_WORKGROUP");
        });
    },
    async DELETE_WORKGROUP(state, data) {
      await api
        .delete(
          `users/group/${data.group_name}/workgroups?workgroup=${data.workgroup_name.name}`
        )
        .then(() => {
          displayErrMsg(
            "GLOBAL_SUCCESS_DELETE",
            "Workgroup",
            "DELETE_WORKGROUP"
          );
          state.workgroups = state.workgroups.filter(
            (workgroups) => workgroups !== data.workgroup_name
          );
        })
        .catch((err) => {
          displayErrMsg(
            err.response.data.code,
            "Workgroup",
            "DELETE_WORKGROUP"
          );
        });
    },
    async GET_ROLES(state) {
      await api
        .get("users/roles")
        .then((response) => {
          state.roles = response.data;
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Perfis", "GET_ROLES");
        });
    },
    SET_USER(state, data) {
      state.user = data;
    },
    async CREATE_SAML_SETTINGS(state, data) {
      await api
        .post(`auth/saml/settings`, data)
        .then((response) => {
          displayErrMsg("GLOBAL_SUCCESS_ADD", "SAML", "CREATE_SAML_SETTINGS");
          state.saml =
            response.data && response.data.length > 0 ? response.data[0] : [];
          router.push({
            name: "group",
          });
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "SAML", "CREATE_SAML_SETTINGS");
        });
    },
    async GET_SAML_SETTINGS(state, data) {
      await api
        .get(`auth/saml/settings?group=${data.group_name}`)
        .then((response) => {
          state.saml =
            response.data && response.data.length > 0 ? response.data[0] : [];
        })
        .catch((err) => {
          state.saml = null;
          displayErrMsg(err.response.data.code, "SAML", "GET_SAML_SETTINGS");
        });
    },
    async UPDATE_SAML_SETTINGS(state, data) {
      await api
        .put(`auth/saml/settings`, data)
        .then((response) => {
          displayErrMsg(
            "GLOBAL_SUCCESS_MODIFY",
            "SAML",
            "UPDATE_SAML_SETTINGS"
          );
          state.saml =
            response.data && response.data.length > 0 ? response.data[0] : [];
          router.push({
            name: "group",
          });
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "SAML", "UPDATE_SAML_SETTINGS");
        });
    },
    async DELETE_SAML_SETTINGS(state, data) {
      await api
        .delete(`auth/saml/settings?group=${data.group_name}`)
        .then((response) => {
          displayErrMsg(
            "GLOBAL_SUCCESS_DELETE",
            "SAML",
            "DELETE_SAML_SETTINGS"
          );
          state.saml = null;
          router.push({
            name: "group",
          });
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "SAML", "DELETE_SAML_SETTINGS");
        });
    },
  },
  getters: {
    user: (state) => state.user,
    users: (state) => state.users,
    roles: (state) => state.roles,
    groups: (state) => state.groups,
    group: (state) => state.group,
    workgroups: (state) => state.workgroups,
    groupWorkgroup: (state) => state.groupWorkgroup,
    saml: (state) => state.saml,
  },
  actions: {
    get(context) {
      context.commit("GET_USERS");
    },
    getGroup(context, data) {
      context.commit("GET_GROUP", { data });
    },
    getGroups(context) {
      context.commit("GET_GROUPS");
    },
    getGroupsAndWorkgroups(context) {
      context.commit("GET_GROUPS_AND_WORKGROUPS");
    },
    getWorkgroups(context, data) {
      context.commit("GET_WORKGROUPS", { data });
    },
    getUserWorkgroups(context, data) {
      context.commit("GET_USER_WORKGROUPS", { data });
    },
    getRoles(context) {
      context.commit("GET_ROLES");
    },
    updateUser(context, data) {
      context.commit("UPDATE_USER", { data });
    },
    updateExternalUser(context, data) {
      context.commit("UPDATE_EXTERNAL_USER", { data });
    },
    deleteUser(context, data) {
      context.commit("DELETE_USER", { data });
    },
    deleteExternalUser(context, data) {
      context.commit("DELETE_EXTERNAL_USER", { data });
    },
    deleteGroup(context, data) {
      context.commit("DELETE_GROUP", { data });
    },
    verifyUser(context, data) {
      return new Promise((resolve, reject) => {
        api.post(`users/${data.email}`).then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    saveUser(context, data) {
      context.commit("SAVE_USER", { data });
    },
    saveUserExternal(context, data) {
      context.commit("SAVE_USER_EXTERNAL", { data });
    },
    saveGroup(context, data) {
      context.commit("SAVE_GROUP", { data });
    },
    updateGroup(context, data) {
      context.commit("UPDATE_GROUP", { data });
    },
    getUserByEmail(context, email) {
      return api
        .get(`users/${email}`)
        .then((response) => {
          context.commit("SET_USER", response.data);
        })
        .catch((err) => {
          displayErrMsg(err.response.data.code, "Usuário", "GET_USER_BY_EMAIL");
        });
    },
    addWorkgroup(context, data) {
      context.commit("ADD_WORKGROUP", data);
    },
    deleteWorkgroup(context, data) {
      context.commit("DELETE_WORKGROUP", data);
    },
    createSAMLSettings(context, data) {
      context.commit("CREATE_SAML_SETTINGS", data);
    },
    getSAMLSettings(context, data) {
      context.commit("GET_SAML_SETTINGS", data);
    },
    updateSAMLSettings(context, data) {
      context.commit("UPDATE_SAML_SETTINGS", data);
    },
    deleteSAMLSettings(context, data) {
      context.commit("DELETE_SAML_SETTINGS", data);
    },
  },
};
