<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="content-header px-0">
      <h1 class="title-I">Configurações de autenticação</h1>
    </div>

    <div class="col-12 col-sm-12 p-0 col-md-12">
      <div class="card-body">
        <div class="pt-4 pr-4 pb-2 pl-4 bg-white rounded">
          <div class="row mb-2">
            <div class="col-12">
              <div class="card direct-chat direct-chat-primary employee">
                <div class="card-body custom-card overflow-visible">
                  <ul class="nav nav-tabs bg-main">
                    <li class="nav-item">
                      <a
                        id="info-tab"
                        class="nav-link first"
                        v-bind:class="{
                          active: infoTabSelected,
                        }"
                        v-on:click="changeTab('infoTabSelected')"
                        href="#"
                        aria-current="infoTabSelected"
                        tabindex="-1"
                        >Informações do Grupo</a
                      >
                    </li>
                    <li class="nav-item" v-if="isAuthSelected('SAML')">
                      <a
                        id="samlAuth-tab"
                        class="nav-link"
                        v-bind:class="{
                          active: samlAuthSelected,
                        }"
                        v-on:click="changeTab('samlAuth')"
                        href="#"
                        aria-current="samlAuth"
                        tabindex="-1"
                        >SAML</a
                      >
                    </li>
                  </ul>
                  <div class="p-4 bg-white rounded">
                    <div class="tab-content">
                      <div
                        class="tab-pane fade"
                        v-bind:class="{
                          show: infoTabSelected,
                          active: infoTabSelected,
                        }"
                      >
                        <div
                          class="title-s nav-tabs pt-4"
                          style="font-size: 20px; padding-bottom: 10px"
                        >
                          Informações de Autenticação
                        </div>
                        <div class="row">
                          <div class="card-body">
                            <div class="p-4 bg-white rounded">
                              <div class="col-6">
                                <div class="row">
                                  <GenInput label="Nome do Grupo" disabled>
                                    <input
                                      id="name"
                                      v-model="groupName"
                                      type="text"
                                      class="form-control"
                                      disabled
                                    />
                                  </GenInput>
                                </div>
                                <div class="row">
                                  <GenInput label="Métodos de Autenticação">
                                    <select
                                      id="groupselectauth"
                                      v-model="selectedAuth"
                                      multiple
                                      class="form-control p-0"
                                    >
                                      <option
                                        v-for="(auth, index) in authOptions"
                                        :key="index"
                                        class="p-2"
                                        :value="auth"
                                      >
                                        {{ auth }}
                                      </option>
                                    </select>
                                    <small
                                      id="permissionaHelp"
                                      class="form-text text-muted"
                                    >
                                      Clique e segure o botão "ctrl" e selecione
                                      os níveis desejados com o clique do mouse.
                                    </small>
                                  </GenInput>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        v-bind:class="{
                          show: samlAuthSelected,
                          active: samlAuthSelected,
                        }"
                      >
                        <div
                          class="title-s nav-tabs pt-4"
                          style="font-size: 20px; padding-bottom: 10px"
                        >
                          Configurações da plataforma
                        </div>
                        <div class="row">
                          <div class="card-body">
                            <div class="p-4 bg-white rounded">
                              <div class="col-12">
                                <div class="row">
                                  <GenInput
                                    label="Identificador da Entidade"
                                    required="true"
                                    col_lg="4"
                                  >
                                    <i
                                      title="Campo 'entityId' do certificado"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <input
                                      id="entityId"
                                      type="text"
                                      class="form-control"
                                      placeholder="https://link.com/auth/saml/metadata/"
                                      v-model="entityId"
                                      required
                                    />
                                  </GenInput>
                                  <GenInput
                                    label="URL do Serviço de Consumo"
                                    required="true"
                                    col_lg="4"
                                  >
                                    <i
                                      title="Campo 'assertionConsumerServiceUrl' do certificado"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <input
                                      id="assertionConsumerServiceUrl"
                                      type="text"
                                      class="form-control"
                                      placeholder="https://link.com/auth/saml/acs"
                                      v-model="assertionConsumerServiceUrl"
                                      required
                                    />
                                  </GenInput>
                                  <GenInput
                                    label="URL do Serviço de Logout"
                                    col_lg="4"
                                  >
                                    <i
                                      title="Campo 'singleLogoutServiceUrl' do certificado"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <input
                                      id="singleLogoutServiceUrl"
                                      type="text"
                                      class="form-control"
                                      placeholder="https://link.com/auth/saml/sls"
                                      v-model="singleLogoutServiceUrl"
                                    />
                                  </GenInput>
                                </div>
                                <!-- <div class="row">
                                  <GenInput
                                    label="Certificado x509"
                                    col_lg="12"
                                  >
                                    <i
                                      title="Certificado em formato PEM"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <textarea
                                      id="certificate"
                                      type="text"
                                      class="form-control"
                                      placeholder="-----BEGIN CERTIFICATE-----"
                                      v-model="certificate"
                                      required
                                    />
                                  </GenInput>
                                </div>
                                <div class="row">
                                   <GenInput
                                    label="Chave Privada"
                                    col_lg="12"
                                  >
                                    <i
                                      title="Chave Privada do Certificado"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <textarea
                                      id="privateKey"
                                      type="text"
                                      class="form-control"
                                      placeholder="MzNaFw0yNTEwMjMzNaMDQxMjAw...VBAMTKU1pY3JenVSBGRlcmF0ZWQg"
                                      v-model="certificateKey"
                                      required
                                    /> 
                                  </GenInput>
                                </div>-->
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="title-s nav-tabs"
                          style="font-size: 20px; padding-bottom: 10px"
                        >
                          Configurações de certificado SAML
                        </div>
                        <div class="row">
                          <div class="card-body">
                            <div class="p-4 bg-white rounded">
                              <div class="col-12">
                                <div class="row">
                                  <GenInput
                                    label="Identificador da Entidade"
                                    required="true"
                                    col_lg="4"
                                  >
                                    <i
                                      title="Campo 'entityId' do certificado"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <input
                                      id="entityIdCert"
                                      type="text"
                                      class="form-control"
                                      placeholder="https://sts.windows.net/hash/"
                                      v-model="entityIdCert"
                                      required
                                    />
                                  </GenInput>
                                  <GenInput
                                    label="URL do Serviço de SSO"
                                    required="true"
                                    col_lg="4"
                                  >
                                    <i
                                      title="URL para fazer o Login com SSO"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <input
                                      id="ssoURL"
                                      type="text"
                                      class="form-control"
                                      placeholder="https://login.microsoftonline.com/hash"
                                      v-model="ssoUrl"
                                      required
                                    />
                                  </GenInput>
                                  <GenInput
                                    label="URL do Serviço de Logout"
                                    col_lg="4"
                                  >
                                    <i
                                      title="Campo 'singleLogoutServiceUrl' do certificado"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <input
                                      id="singleLogoutServiceUrl"
                                      type="text"
                                      class="form-control"
                                      placeholder="https://login.microsoftonline.com/hash"
                                      v-model="singleLogoutServiceUrlCert"
                                    />
                                  </GenInput>
                                </div>
                                <div class="row">
                                  <GenInput
                                    label="Certificado x509"
                                    required="true"
                                    col_lg="12"
                                  >
                                    <i
                                      title="Certificado em formato PEM"
                                      class="fas fa-question-circle"
                                      style="margin-left: 5px"
                                    >
                                    </i>
                                    <textarea
                                      id="certificate-saml"
                                      type="text"
                                      class="form-control"
                                      placeholder="-----BEGIN CERTIFICATE-----"
                                      v-model="certificateCert"
                                      required
                                    />
                                  </GenInput>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-1">
              <button
                id="saveEditGateway"
                class="btn btn-primary px-5"
                v-on:click="updateGroup"
                :disabled="load"
              >
                <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
                Salvar
              </button>
              <button
                id="saveDeleteGateway"
                v-if="saml && saml.group"
                class="btn btn-danger px-5 ml-2"
                v-on:click="deleteGroup"
                :disabled="load"
              >
                <i v-if="load" class="fas fa-sync-alt fa-spin"></i>
                Deletar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import GenInput from "@/components/GenInput.vue";
export default {
  name: "GroupConfig",
  data: function () {
    return {
      tabSelected: "infoTabSelected",
      breadcrumbList: [],
      groupName: "",
      selectedAuth: [],
      authOptions: ["SAML"],
      entityId: "",
      assertionConsumerServiceUrl: "",
      singleLogoutServiceUrl: "",
      // certificate: "",
      // certificateKey: "",
      entityIdCert: "",
      ssoUrl: "",
      singleLogoutServiceUrlCert: "",
      certificateCert: "",
    };
  },
  created() {
    this.groupName = this.$route.params.groupName;
    this.updateList();

    if (this.$route.params.groupName) {
      this.$store.dispatch("user/getSAMLSettings", {
        group_name: this.$route.params.groupName,
      });
    }
  },
  mounted() {
    this.entityId = window.location.origin + "/v0/auth/saml/metadata/";
    this.assertionConsumerServiceUrl =
      window.location.origin + "/v0/auth/saml/acs";
    this.singleLogoutServiceUrl = window.location.origin + "/v0/auth/saml/sls";
  },
  watch: {
    $route() {
      this.updateList();
    },
    saml() {
      this.entityId = this.saml.sp.entityId;
      this.assertionConsumerServiceUrl = this.saml.sp.assertionConsumerServiceUrl.replace(/\/$/, "");
      this.singleLogoutServiceUrl = this.saml.sp.singleLogoutServiceUrl.replace(/\/$/, "");
      // this.certificate = this.saml.sp.x509cert;
      // this.certificateKey = this.saml.sp.privateKey;
      this.entityIdCert = this.saml.idp.entityId;
      this.singleLogoutServiceUrlCert = this.saml.idp.singleLogoutServiceUrl;
      this.ssoUrl = this.saml.idp.singleSignOnServiceUrl;
      this.certificateCert = this.saml.idp.x509cert;
    },
  },
  methods: {
    changeTab(newTab) {
      this.tabSelected = newTab;
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    updateGroup() {
      if (
        this.entityId === "" ||
        this.assertionConsumerServiceUrl === "" ||
        this.singleLogoutServiceUrl === "" ||
        // this.certificate === "" ||
        // this.certificateKey === "" ||
        this.entityIdCert === "" ||
        this.ssoUrl === "" ||
        this.singleLogoutServiceUrlCert === "" ||
        this.certificateCert === ""
      ) {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {field: "Dados do certificado"});
        return;
      }
      let api_obj = {
        group: this.groupName,
        idp: {
          entityId: this.entityIdCert,
          singleLogoutServiceUrl: this.singleLogoutServiceUrlCert,
          singleSignOnServiceUrl: this.ssoUrl,
          x509cert: this.certificateCert,
        },
        sp: {
          assertionConsumerServiceUrl: this.assertionConsumerServiceUrl,
          entityId: this.entityId,
          // privateKey: this.certificateKey,
          singleLogoutServiceUrl: this.singleLogoutServiceUrl,
          // x509cert: this.certificate,
        },
      };
      if (this.saml === null || this.saml.length === 0) {
        this.$store.dispatch("user/createSAMLSettings", api_obj);
      } else {
        this.$store.dispatch("user/updateSAMLSettings", api_obj);
      }
    },
    deleteGroup() {
      this.$store.dispatch("user/deleteSAMLSettings", {
        group_name: this.groupName,
      });
    },
    isAuthSelected(auth) {
      return this.selectedAuth.find((item) => item === auth) !== undefined;
    },
  },
  components: {
    Breadcrumb,
    GenInput,
  },
  computed: {
    samlAuthSelected() {
      return this.tabSelected === "samlAuth";
    },
    infoTabSelected() {
      return this.tabSelected === "infoTabSelected";
    },
    load() {
      return this.$store.getters["load"];
    },
    saml() {
      return this.$store.getters["user/saml"];
    },
  },
};
</script>
<style>
.margin-left-zero {
  margin-left: 0;
}
.eyesight {
  margin-left: 5px;
  position: absolute;
  right: 36px;
  top: 42px;
  cursor: pointer;
}
.same-size-ps {
  width: 150px;
  text-align: left;
  background-color: #e9ecef;
}
</style>
