<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />

    <div class="content-header px-0">
      <h1
        class="title-I"
        v-if="!(rolesSelecteds == 'ADMIN' && !hasRole('ADMIN'))"
      >
        Editar usuário
      </h1>
      <h1 class="title-I" v-else>Editar usuário | Você não tem permissão</h1>
    </div>

    <div
      class="col-12 col-sm-12 p-0 col-md-12"
      v-if="!(rolesSelecteds == 'ADMIN' && !hasRole('ADMIN'))"
    >
      <div class="card direct-chat direct-chat-primary user">
        <div class="card-body">
          <div class="p-4 bg- hite rounded">
            <form @submit="updateUser">
              <div class="row">
                <GenInput label="Nome" required="false">
                  <input
                    id="name"
                    v-model="name"
                    required
                    type="text"
                    class="form-control"
                    placeholder="Nome do usuário"
                    maxlength="55"
                  />
                </GenInput>
                <GenInput label="Celular">
                  <input
                    id="cellphone"
                    v-model="cellphone"
                    v-mask="'+(##) ## #####-####'"
                    type="text"
                    class="form-control"
                    placeholder="Celular"
                  />
                </GenInput>
              </div>
              <div class="row">
                <GenInput label="Email" required="false">
                  <input
                    id="email"
                    v-model="email"
                    required
                    type="email"
                    maxlength="55"
                    class="form-control"
                    disabled
                    readonly
                    placeholder="Email"
                  />
                </GenInput>
              </div>
              <div class="row mt-3">
                <GenInput col_lg="4" label="Status" required="false">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="radioButtonStatus"
                      id="statusACTIVE"
                      value="ACTIVE"
                      v-model="status"
                      size="md"
                      :disabled="storage.getItem('email') === email"
                    />
                    <label class="form-check-label" for="statusACTIVE">
                      ATIVO
                    </label>
                    <i
                      title="O usuário está ativo e tem acesso ao portal"
                      class="fas fa-question-circle"
                      style="margin-left: 10px"
                    >
                    </i>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="radioButtonStatus"
                      id="statusINACTIVE"
                      value="INACTIVE"
                      v-model="status"
                      size="md"
                      :disabled="storage.getItem('email') === email"
                      checked
                    />
                    <label class="form-check-label" for="statusINACTIVE">
                      INATIVO
                    </label>
                    <i
                      title="O usuário está inativo e não tem acesso ao portal"
                      class="fas fa-question-circle"
                      style="margin-left: 10px"
                    >
                    </i>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="radioButtonStatus"
                      id="statusBLOCKED"
                      value="BLOCKED"
                      v-model="status"
                      size="md"
                      checked
                      disabled
                    />
                    <label class="form-check-label" for="statusBLOCKED">
                      BLOQUEADO
                    </label>
                    <i
                      title="O usuário está bloqueado por erro de senha e não tem acesso ao portal"
                      class="fas fa-question-circle"
                      style="margin-left: 10px"
                    >
                    </i>
                  </div>
                </GenInput>
                <GenInput
                  col_lg="4"
                  label="Duplo fator"
                  v-if="user_type === 'LOCAL'"
                >
                  <b-form-checkbox id="tfa" v-model="tfa" name="tfa" size="md">
                    Utilizar duplo fator para autenticar usuário
                    <i
                      title="A configuração de duplo fator definida pelo grupo será prioritária"
                      class="fas fa-question-circle"
                    >
                    </i>
                  </b-form-checkbox>
                </GenInput>
              </div>
              <div class="row mt-3">
                <GenInput col_lg="4" label="Grupo" required="false">
                  <select
                    id="userselect"
                    v-model="groupsSelecteds"
                    multiple
                    required
                    class="form-control p-0"
                  >
                    <option
                      v-for="(group, index) in groups"
                      :key="index"
                      class="p-2"
                      :value="group.name"
                    >
                      {{ group.name }}
                    </option>
                  </select>
                </GenInput>
                <GenInput col_lg="4" label="Workgroups" required="false">
                  <select
                    id="userselectworkgroup"
                    v-model="workgroupsSelecteds"
                    multiple
                    class="form-control p-0"
                  >
                    <option
                      v-for="(workgroup, index) in workgroups"
                      :key="index"
                      class="p-2"
                      :value="workgroup.name"
                    >
                      {{ workgroup.name }}
                    </option>
                  </select>
                  <small id="permissionaHelp" class="form-text text-muted">
                    Clique e segure o botão "ctrl" e selecione os níveis
                    desejados com o clique do mouse.
                  </small>
                </GenInput>
                <GenInput col_lg="4" label="Nível de acesso" required="false">
                  <select
                    id="userselectroles"
                    v-model="rolesSelecteds"
                    multiple
                    class="form-control p-0"
                    :disabled="storage.getItem('email') === email"
                  >
                    <option
                      v-for="(role, index) in roles"
                      :key="index"
                      class="p-2"
                      :value="role.name"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </GenInput>
              </div>

              <!-- TAB HERE-->
              <div
                class="pt-4 pr-4 pb-2 bg-white rounded"
                v-if="user_type === 'LOCAL'"
              >
                <div class="row mb-2">
                  <div class="col-12">
                    <div class="card direct-chat direct-chat-primary">
                      <div class="card-body custom-card overflow-visible">
                        <ul class="nav nav-tabs bg-main">
                          <li class="nav-item">
                            <a
                              id="autentication-tab"
                              class="nav-link first"
                              v-bind:class="{
                                active: autenticationSelected,
                              }"
                              v-on:click="changeTab('autentication')"
                              href="#"
                              aria-current="autentication"
                              tabindex="-1"
                              >Dados de autenticação</a
                            >
                          </li>
                        </ul>
                        <div class="p-4 bg-white rounded">
                          <div class="tab-content">
                            <div
                              v-bind:class="{
                                show: autenticationSelected,
                                active: autenticationSelected,
                              }"
                            >
                              <div class="row">
                                <div class="col">
                                  <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                      <a
                                        id="autenticationAcess"
                                        class="nav-link first"
                                        >Expiração Token Acess</a
                                      >
                                    </li>
                                  </ul>
                                  <GenInput class="pt-4" col_lg="6" label="Dias">
                                    <input
                                      v-model="access_token_expiry.days"
                                      type="number"
                                      class="form-control"
                                      placeholder="Dias"
                                      id="edit-toke-expiry-days"
                                    />
                                  </GenInput>
                                  <GenInput col_lg="6" label="Horas">
                                    <input
                                      v-model="access_token_expiry.hours"
                                      type="number"
                                      class="form-control"
                                      placeholder="Horas"
                                      id="edit-toke-expiry-hrs"
                                    />
                                  </GenInput>
                                  <GenInput col_lg="6" label="Minutos">
                                    <input
                                      v-model="access_token_expiry.minutes"
                                      type="number"
                                      class="form-control"
                                      placeholder="Minutos"
                                      id="accessMinutes"
                                    />
                                  </GenInput>
                                  <GenInput col_lg="6" label="Segundos">
                                    <input
                                      v-model="access_token_expiry.seconds"
                                      type="number"
                                      class="form-control"
                                      placeholder="Segundos"
                                      id="accessSeconds"
                                    />
                                  </GenInput>
                                </div>

                                <div class="col">
                                  <ul class="nav nav-tabs">
                                    <li class="nav-item">
                                      <a
                                        id="autenticationRefresh"
                                        class="nav-link first"
                                        >Expiração Token Refresh</a
                                      >
                                    </li>
                                  </ul>
                                  <GenInput class="pt-4" col_lg="6" label="Dias">
                                    <input
                                      v-model="refresh_token_expiry.days"
                                      type="number"
                                      class="form-control"
                                      placeholder="Dias"
                                      id="edit-refresh-expiry-days"
                                    />
                                  </GenInput>
                                  <GenInput col_lg="6" label="Horas">
                                    <input
                                      v-model="refresh_token_expiry.hours"
                                      type="number"
                                      class="form-control"
                                      placeholder="Horas"
                                      id="edit-refresh-expiry-hrs"
                                    />
                                  </GenInput>
                                  <GenInput col_lg="6" label="Minutos">
                                    <input
                                      v-model="refresh_token_expiry.minutes"
                                      type="number"
                                      class="form-control"
                                      placeholder="Minutos"
                                      id="refreshMinutes"
                                    />
                                  </GenInput>
                                  <GenInput col_lg="6" label="Segundos">
                                    <input
                                      v-model="refresh_token_expiry.seconds"
                                      type="number"
                                      class="form-control"
                                      placeholder="Segundos"
                                      id="refreshSeconds"
                                    />
                                  </GenInput>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row p-1">
                <button
                  id="usereditsave"
                  class="btn btn-primary px-5"
                  :disabled="load"
                >
                  <i v-if="load" class="fas fa-sync-alt fa-spin"></i> Salvar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import GenInput from "@/components/GenInput.vue";
import hasRole from "../../utils/hasRole";
import "./style.scss";
export default {
  name: "EditUser",
  data: function () {
    return {
      breadcrumbList: [],
      email: "",
      name: "",
      password: "",
      tfa: false,
      cellphone: "",
      emailError: false,
      passShow: false,
      rolesSelecteds: [],
      groupsSelecteds: [],
      workgroupsSelecteds: [],
      access_token_expiry: {
        days: 0,
        hours: 0,
        minutes: 15,
        seconds: 0,
      },
      refresh_token_expiry: {
        days: 0,
        hours: 0,
        minutes: 60,
        seconds: 0,
      },
      user_type: "LOCAL",
      status: "INACTIVE",
      storage: null,
    };
  },
  beforeCreate() {
    // Check if route params has an user parameter
    if (!this.$route.params.user) {
      // set this.$route.params.user to the object inside the localStorage
      this.userData = JSON.parse(localStorage.getItem("user"));
      if (!this.userData) {
        window.frontMsg(
          "FRNT_MISSING_WARNING",
          {
            frontelement: "do Usuário",
          }
        );
        this.$router.push("/user");
      }
    } else {
      // Save the data to localstorage
      this.userData = this.$route.params.user;
      localStorage.setItem("user", JSON.stringify(this.$route.params.user));
    }
  },
  created() {
    const {
      cellphone,
      email,
      tfa,
      name,
      roles,
      workgroups,
      user_groups,
      access_token_expiry,
      refresh_token_expiry,
      type,
      status,
    } = this.userData;
    this.cellphone = cellphone;
    this.email = email;
    this.tfa = tfa;
    this.name = name;
    this.rolesSelecteds = roles;
    this.workgroupsSelecteds = workgroups;
    this.user_type = type;
    this.status = status;
    this.groupsSelecteds = [user_groups.name];
    this.access_token_expiry = {
      days: access_token_expiry?.days || 0,
      hours: access_token_expiry?.hours || 0,
      minutes: access_token_expiry?.minutes || 0,
      seconds: access_token_expiry?.seconds || 0,
    };
    this.refresh_token_expiry = {
      days: refresh_token_expiry?.days || 0,
      hours: refresh_token_expiry?.hours || 0,
      minutes: refresh_token_expiry?.minutes || 0,
      seconds: refresh_token_expiry?.seconds || 0,
    };
  },
  mounted() {

    this.storage =
      localStorage.getItem("rememberMe") === "true"
        ? window.localStorage
        : window.sessionStorage;
    
    this.$store.dispatch("user/getGroupsAndWorkgroups");
    this.$store.dispatch("user/getRoles");
    this.$store.dispatch("user/get");
    this.$store.dispatch("user/getWorkgroups", {
      group_name: this.userData.user_groups.name,
    });
    this.updateList();
  },
  watch: {
    groupsSelecteds() {
      if (this.groupsSelecteds.length > 1)
        this.groupsSelecteds = [this.groupsSelecteds.slice(-1).pop()];
      // put group's TFA as default
      this.$store.dispatch("user/getWorkgroups", {
        group_name: this.groupsSelecteds[0],
      });
    },
    rolesSelecteds() {
      if (this.rolesSelecteds.length > 1)
        this.rolesSelecteds = [this.rolesSelecteds.slice(-1).pop()];
    },
  },
  methods: {
    hasRole,
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    updateUser() {
      event.preventDefault();

      if (this.tfa == null) {
        this.tfa = false;

        return this.tfa;
      }

      this.access_token_expiry = {
        days: ~~this.access_token_expiry.days || 0,
        hours: ~~this.access_token_expiry.hours || 0,
        minutes: ~~this.access_token_expiry.minutes || 0,
        seconds: ~~this.access_token_expiry.seconds || 0,
      };
      this.refresh_token_expiry = {
        days: ~~this.refresh_token_expiry.days || 0,
        hours: ~~this.refresh_token_expiry.hours || 0,
        minutes: ~~this.refresh_token_expiry.minutes || 0,
        seconds: ~~this.refresh_token_expiry.seconds || 0,
      };

      if (!this.isUserValid()) {
        return;
      }
      let user_obj = {
        name: this.name,
        status: this.status,
        cellphone: this.cellphone.replace(/[\s()-]/g, ""),
        email: this.email,
        roles: this.rolesSelecteds ? this.rolesSelecteds : [],
        workgroups: this.workgroupsSelecteds ? this.workgroupsSelecteds : [],
        user_groups: { name: this.groupsSelecteds[0] },
      };
      if(user_obj.status === "BLOCKED") delete user_obj.status;
      if (this.user_type === "EXTERNAL") {
        this.$store.dispatch("user/updateExternalUser", user_obj);
      } else {
        user_obj.access_token_expiry = this.access_token_expiry;
        user_obj.refresh_token_expiry = this.refresh_token_expiry;
        user_obj.tfa = this.tfa;
        this.$store.dispatch("user/updateUser", user_obj);
      }
      this.$router.push("/user");
    },
    isUserValid() {
      //Checks if the data is valid.
      if (!this.name || this.name.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "Nome" }
        );
        return false;
      }

      if (
        (this.cellphone.replace(/[\s()+-]/g, "").length < 11 ||
          this.cellphone.replace(/[\s()+-]/g, "").length > 14) &&
        this.cellphone !== ""
      ) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "Número de Celular" }
        );
        return false;
      }

      for (let user of this.users) {
        if (user.cellphone) {
          if (
            user.cellphone.replace(/[\s()-]/g, "") ===
              this.cellphone.replace(/[\s()-]/g, "") &&
            user.email !== this.email
          ) {
            window.frontMsg(
              "FRNT_IN_USE_WARNING",
              { field: "Número de Celular" }
            );
            return false;
          }
        }
      }

      if (!this.email || this.email.trim() === "") {
        window.frontMsg(
          "FRNT_EMPTY_WARNING",
          { field: "E-mail" }
        );
        return false;
      }

      if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.email.trim()
        )
      ) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          { field: "E-mail" }
        );
        return false;
      }

      if (
        !this.groupsSelecteds ||
        this.groupsSelecteds.length != 1 ||
        this.groupsSelecteds[0].trim() === ""
      ) {
        window.frontMsg(
          "FRNT_INVALID_FIELD_WARNING",
          {
            field: "Grupo",
            extra: "Selecione um grupo para o usuário",
          }
        );
        return false;
      }

      if (
        Number(this.access_token_expiry.days) &&
        Number(this.access_token_expiry.days) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Dias", numbercondition: "inteiro maior que zero" }
        );
        return false;
      }

      if (
        Number(this.access_token_expiry.hours) &&
        Number(this.access_token_expiry.hours) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Horas", numbercondition: "inteiro maior que zero" }
        );
        return false;
      }
      if (
        Number(this.access_token_expiry.minutes) &&
        Number(this.access_token_expiry.minutes) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Minutos", numbercondition: "inteiro maior que zero" }
        );
        return false;
      }
      if (
        Number(this.access_token_expiry.seconds) &&
        Number(this.access_token_expiry.seconds) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Segundos", numbercondition: "inteiro maior que zero" }
        );
        return false;
      }
      if (
        Number(this.refresh_token_expiry.days) &&
        Number(this.refresh_token_expiry.days) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Dias", numbercondition: "inteiro maior que zero" }
        );
        return false;
      }
      if (
        Number(this.refresh_token_expiry.hours) &&
        Number(this.refresh_token_expiry.hours) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Horas", numbercondition: "inteiro maior que zero" }
        );
        return false;
      }
      if (
        Number(this.refresh_token_expiry.minutes) &&
        Number(this.refresh_token_expiry.minutes) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Minutos", numbercondition: "inteiro maior que zero" }
        );
        return false;
      }
      if (
        Number(this.refresh_token_expiry.seconds) &&
        Number(this.refresh_token_expiry.seconds) < 0
      ) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          { field: "Segundos", numbercondition: "inteiro maior que zero" }
        );
        return false;
      }

      const access_seconds =
        ~~this.access_token_expiry.days * 24 * 60 * 60 +
        ~~this.access_token_expiry.hours * 60 * 60 +
        ~~this.access_token_expiry.minutes * 60 +
        ~~this.access_token_expiry.seconds;
      const refresh_seconds =
        ~~this.refresh_token_expiry.days * 24 * 60 * 60 +
        ~~this.refresh_token_expiry.hours * 60 * 60 +
        ~~this.refresh_token_expiry.minutes * 60 +
        ~~this.refresh_token_expiry.seconds;

      if (access_seconds > refresh_seconds) {
        window.frontMsg(
          "FRNT_NUM_RANGE_WARNING",
          {
            field: "Duração do Token de Acesso",
            numbercondition:
              "que não pode ser maior que a duração do token de atualização",
          }
        );
        return false;
      }
      return true;
    },
  },
  components: {
    Breadcrumb,
    GenInput,
  },
  computed: {
    users() {
      return this.$store.getters["user/users"];
    },
    groups() {
      return this.$store.getters["user/groups"];
    },
    workgroups() {
      return this.$store.getters["user/workgroups"];
    },
    roles() {
      return this.$store.getters["user/roles"];
    },
    load() {
      return this.$store.getters["load"];
    },
    autenticationSelected() {
      return this.tabSelected === "autentication";
    },
  },
};
</script>
